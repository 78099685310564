<template>
  <v-card class="my-4" elevation="4">
    <v-card-title> <v-icon class="mr-2">mdi-cellphone-settings-variant</v-icon> Configurações de Atualização </v-card-title>
    <v-card-text class="ma-2">
      <v-row no-gutters>
        <v-col cols="4" class="mr-4">
          <span class="pb-2">Versão Mínima Android</span>
          <v-text-field v-model="android.minimumVersion" label="Versão Mínima Android" type="text" full-width outlined></v-text-field>
        </v-col>
        <v-col cols="4" class="ml-4">
          <span class="pb-2">Versão Recomendada Android</span>
          <v-text-field v-model="android.recommendedVersion" label="Versão Recomendada Android" type="text" full-width outlined></v-text-field>
        </v-col>
        <v-col cols="4" class="mr-4">
          <span class="pb-2">Versão Mínima iOS</span>
          <v-text-field v-model="ios.minimumVersion" label="Versão Mínima iOS" type="text" full-width outlined></v-text-field>
        </v-col>
        <v-col cols="4" class="mx-4">
          <span class="pb-2">Versão Recomendada iOS</span>
          <v-text-field v-model="ios.recommendedVersion" label="Versão Recomendada iOS" type="text" full-width outlined></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-switch v-model="manuallyForceUpdate" label="Forçar Atualização Manualmente" outlined></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="dialog = true"><v-icon class="mr-1">sync</v-icon> Atualizar Configurações</v-btn>
    </v-card-actions>
    <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @proceed="submitUpdate" @cancel="closeDialog" />
  </v-card>
</template>

<script>
import { QUERY_CONFIGURATION, MUTATION_UPDATE_APP_VERSION } from '@/modules/accounts/graphql'

export default {
  components: {
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  props: {
    accountId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    android: {
      minimumVersion: '',
      recommendedVersion: ''
    },
    ios: {
      minimumVersion: '',
      recommendedVersion: ''
    },
    manuallyForceUpdate: false,
    dialog: false,
    textDialog: 'Tem certeza que deseja atualizar as configurações?'
  }),
  apollo: {
    configuration: {
      query: QUERY_CONFIGURATION,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      update(data) {
        const config = data.configuration.app_update
        this.android.minimumVersion = config.android.minimum_version
        this.android.recommendedVersion = config.android.recommended_version
        this.ios.minimumVersion = config.ios.minimum_version
        this.ios.recommendedVersion = config.ios.recommended_version
        this.manuallyForceUpdate = config.manually_force_update
      },
      error(error) {
        console.error('Erro ao buscar a configuração:', error)
      }
    }
  },

  methods: {
    openConfirmationDialog() {
      this.dialog = true
    },
    async submitUpdate() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_VERSION,
          variables: {
            accountId: this.accountId,
            input: {
              android: this.android,
              ios: this.ios,
              manuallyForceUpdate: this.manuallyForceUpdate
            }
          }
        })
        this.$snackbar({ message: 'Configurações atualizadas com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao atualizar as configurações', snackbarColor: '#F44336' })
      }
      this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
    }
  }
}
</script>
